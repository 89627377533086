import React, { useState, useEffect } from 'react'

const Cringe: React.FC = () => {
    const calculateTimeUntil = () => {
        // Calculate the time until December 16, 2024 at 3:05 PM German time

        const targetDate = new Date('2024-12-16T14:05:00')
        const currentDate = new Date()

        let difference = targetDate.getTime() - currentDate.getTime()

        let days = Math.floor(difference / (1000 * 60 * 60 * 24))
        difference -= days * 1000 * 60 * 60 * 24

        let hours = Math.floor(difference / (1000 * 60 * 60))

        difference -= hours * 1000 * 60 * 60

        let minutes = Math.floor(difference / (1000 * 60))

        difference -= minutes * 1000 * 60

        let seconds = Math.floor(difference / 1000)

        difference -= seconds * 1000

        let milliseconds = difference

        return { days, hours, minutes, seconds, milliseconds }
    }

    const [timeUntil, setTimeUntil] = useState(calculateTimeUntil())

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeUntil(calculateTimeUntil())
        }, 1) // Update every millisecond

        return () => clearInterval(interval)
    }, [])

    return (
        <div>
            <div className="cringe-container" id="cringe">
                <h1>Time until Hailey is in Europe!</h1>
                <p>
                    Only<br></br>
                    {timeUntil.days} days<br></br>
                    {timeUntil.hours}hours,<br></br>
                    {timeUntil.minutes} minutes,<br></br>
                    {timeUntil.seconds} seconds, and<br></br>
                    {timeUntil.milliseconds} milliseconds left!
                </p>
            </div>
        </div>
    )
}

export default Cringe
